body {
  overflow: visible;
  font-family: "brfirma-regular";
}
.section-1 {
  background-image: url(../../../assets/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 159px;
}
.contact_us_div {
  padding-top: 300px;
  color: #fff;
}
.heading_primary {
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 12px;
}
.section-2 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.contactform {
  text-align: center;
  padding: 45px 28px 41px 28px;
  border: 1px solid #ececec;
  border-radius: 8px;
  box-shadow: -2px 6px 21px -4px rgba(0, 0, 0, 0.2);
}
.contact_form_header {
  margin-bottom: 31px;
}
.contact_info {
  border: none;
  background-color: #f5f5f5;
  outline: none;
  padding: 10px !important;
  margin-bottom: 9px;
  border-radius: 6px;
  resize: inherit;
}
.contact_info:focus {
  background-color: #f5f5f5;
  border: none !important;
  outline: none !important;
}
.contact_info::placeholder {
  font-size: 12px;
  color: #929a9f;
}
.contact_info:focus {
  background-color: #f5f5f5;
  border: none !important;
  outline: none !important;
}
.contact_btn {
  display: inline-block;
  height: 20px;
  width: 100px;
  background-color: #9c1258;
  color: #fff;
  margin-top: 23px;
  padding: 10px 0px 30px 0px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}
.cnrtctfrmd {
  display: flex;
}
.contact_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
.cnticns {
  margin-bottom: 13px;
}
.contact_map {
  display: flex;
  justify-content: center;
}
@media (max-width: 760px) {
  .contactform {
    margin-bottom: 57px;
  }
  .contact_social {
    margin-top: 40px;
  }
}
.social_icn {
  display: inline-block;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.twitter {
  background-image: url(../../../assets/twitter_icn.png);
}
.facebook {
  background-image: url(../../../assets/facebookicn.png);
  margin-left: 25px;
}
.linkedin {
  background-image: url(../../../assets/linkedin_icn.png);
  margin-left: 25px;
}
.contact_social {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.section-3 {
  background-color: #0c9fb3;
  color: #fff;
}
.help-section {
  text-align: center;
  padding: 65px 0px 65px 0px;
}
.help-btn {
  display: inline-block;
  background-color: #fff;
  color: #0c9fb3;
  height: 38px;
  width: 157px;
  margin-top: 17px;
  border-radius: 7px;
  cursor: pointer;
  padding-top: 8px;
}
.cntfrm-mssg {
  left: 0%;
}
.newsection-1 {
  padding-left: 5rem;
  padding-right: 20%;
}
.pry_heading2 {
  font-size: 40px;
  font-family: "Brfirma-Bold";
  margin-bottom: 1.5rem;
}
.cntctdiv {
  padding-top: 16rem;
}

@media(max-width: 100px) {
  .newsection-1 {
    padding-left: 3.5rem;
  }
}

@media (max-width: 600px) {
  .newsection-1 {
    padding-left: 3rem;
    padding-bottom: 8rem;
  }
  .cntctdiv {
    padding-top: 12rem;
  }
}

@media (max-width: 800px) {
  .pry_heading2 {
    font-size: 36px;
  }
  .weare {
    letter-spacing: 0.3px;
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .pry_heading2 {
    font-size: 32px;
  }
  .weare {
    font-size: 14px;
    line-height: 24px;
  }
  .newsection-1 {
    padding-left: 2rem;
  }
  .cntctdiv {
      padding-top: 10rem;
  }
}