.assesment_header {
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #9C125F;
  padding-left: 2rem;
  padding-bottom: 2.2rem;
}
.flipdirection {
  flex-direction: row-reverse;
}
.jcenter {
  justify-content: center !important;
  width: 100%;
}
.jcenter1 {
  justify-content: center;
  width: 100%;
}
.modal-title {
  font-size: 18px !important;
  color: #2c2147;
}
.modal-body {
  font-size: 14px !important;
  word-spacing: 1.4;
  line-height: 1.6;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.wrapassess {
  display: flex;
  justify-content: space-between;
  border-top: 15px;
  background-color: #fbfbfb;
  padding: 1rem 0.1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.secondwidth {
  border-radius: 34px;
  border: solid 2px #9C125F;
  padding: 4px 1rem;
  background: white;
  display: flex;
  align-items: center;
}
.progressbarwrapper {
  background-color: #fbfbfb;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
}
.spacol {
  width: 15px;
  margin-right: 7px;
}
.sdsd {
  font-size: 12px;
  /* font-family: "Ubuntu" */;
  font-stretch: normal;
  font-weight: 400;
  text-transform: capitalize;
  font-style: normal;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.firstrowcf {
  padding-top: 2rem;
  padding-bottom: 4rem;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.05);
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.firstrowcf2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  /* margin-top: 2rem; */
  background: #fbfbfb;
  margin-left: 2px;
  margin-right: 2px;
}
.firstquestion {
  background: #fff;
  padding: 1rem 2rem;
  font-weight: 400;
}
.rsliderclass {
  width: 75%;
  margin: auto;
  padding-top: 0.5rem;
}
.rsliderclass2 {
  margin: auto;
  line-height: 1.28;
  display: flex;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
  justify-content: space-between;
}
.range-slider--warning {
  background: #9C125F;
}
.nxtbtn {
  background: #9C125F;
  color: #fff;
  border: none;
  padding: 0.5rem 3rem;
  border-radius: 25px;
}

.firstassess {
  margin-bottom: 2rem;
}
.nxtbtnarea {
  display: block;
  text-align: center;
  padding: 4rem 1rem 2rem 1rem;
}
.cherry-done {
  width: 35%;
  margin-bottom: 2rem;
}
.awesomewrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
}
.awesome {
  font-size: 1.3em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #9C125F;
  margin: 0.5rem;
}
.awesome1 {
  font-weight: 300;
  font-stretch: normal;
  /* font-family: "Ubuntu" */;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.awesomebtn {
  background: #9C125F;
  color: #fff;
  border: 2px solid #9C125F;
  padding: 0.5rem 3rem;
  border-radius: 25px;
  min-width: 266px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.awesomebtnsubmit {
  background: white;
  border: 2px solid #9C125F;
  color: #9C125F;
  padding: 0.5rem 3rem;
  border-radius: 25px;
  min-width: 266px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.4rem;
}
.p2answers {
  width: 240px;
  margin-left: 14px;
}
.answers {
  width: 100%;
  max-height: 165px;
  min-height: 163px;
}
.questTwo {
  font-size: 13px;
  background: white;
  text-align: center;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #3d3c38;
  min-height: 37px;
}
.rsliderclassPol {
  width: 75%;
  margin: auto;
  padding-top: 0.5rem;
  display: flex;
}
.rsliderclassPol1 {
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 0.5rem;
  margin-left: 0rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 0.3rem;
}
@media (min-width: 701px) {
  .losos {
    padding: 1rem 1rem 1rem 4rem;
  }
  .rsliderclass9 {
    width: 75%;
  }
  .rsliderclass2 {
    width: 75%;
  }
  .rightarrow {
    font-size: 4.5rem;
    color: #ffbc41;
    cursor: pointer;
    font-weight: bolder;
  }
  .smalldisplay {
    display: none;
    align-items: center;
  }
  .mdss1 >.modal-dialog{
    width: 30%;
  }
  .mdss1 {
    /* width: 40%; */
    margin: auto !important;
  }
  .ocenter {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 700px) {
  .rsliderclassPol1 {
    margin-left: 0rem;
  }
  .rsliderclass2 {
    font-size: 12px;
  }
  .rightarrow {
    font-size: 3rem;
    color: #ffbc41;
    cursor: pointer;
  }
  .cherry-done {
    width: 39%;
  }
  .smalldisplay {
    display: block;
  }
  .bighide {
    display: none;
  }
  .creative {
    padding: 1rem 0px;
  }
  .rsliderclass2 {
    widows: 95%;
  }
  .firstquestion {
    padding: 0px;
  }
  .rsliderclass9 {
    width: fit-content;
  }
  .secondwidth {
    padding: 4px 0.4rem;
  }
  .col-md-11 {
    padding: 0px;
  }
  .losos {
    padding: 0rem 0rem 0rem 2px;
  }
}
/* checked box starts here */
.checkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1.5px solid rgb(209, 204, 204);
}

/* On mouse-over, add a grey background color */
.checkcontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkcontainer input:checked ~ .checkmark {
  background-color: #9C125F;
  border: 2px solid #9C125F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkcontainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  /* border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); */
}
.checkwrapper {
  display: flex;
  justify-content: space-between;
  flex: 2;
  padding-left: 1rem;
}
.rsliderclass9 {
  margin: auto;
  line-height: 1.28;
  display: flex;
  letter-spacing: normal;
  text-align: left;
  color: rgb(170, 169, 169);
  padding-top: 1rem;
  justify-content: space-between;
}
@media (max-width: 411px) {
  .rsliderclass9 {
    display: flex;
    flex-wrap: wrap;
    order: 4;
  }
  .agree {
    order: 3;
    padding-top: 1rem;
  }
  .disagree {
    order: 1;
    padding-top: 1rem;
  }
}
.doubled {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.creative {
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #9C125F;
}

.skip123 {
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  text-transform: lowercase;
  font-style: normal;
  line-height: 1.27;
  width: fit-content;
  letter-spacing: normal;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  color: #3d3c38;
}
.assessquestionwrap {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.assessquestion {
  font-size: 1.2em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #9C125F;
  align-self: center;
}
.assessrating {
  font-size: 1.5rem;
  min-width: 120px;
  display: flex;
  align-items: center;
}

.rightarrow:hover {
  animation: moveforward;
  animation-duration: 6s;
  animation-play-state: running;
}
@keyframes moveforward {
  0% {
    padding-left: 0.1rem;
  }
  25% {
    padding-left: 1rem;
  }
  50% {
    padding-left: 0.2rem;
  }
  100% {
    padding-left: 1rem;
  }
}

.chooseheader {
  text-transform: uppercase;
  font-size: 1.5em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #9C125F;
}
a:hover {
  text-decoration: none;
}
.chooseheader1 {
  font-size: 1.2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  text-align: center;
  color: #2c2147;
}
.plancost1 {
  font-size: 1.2em;
  font-weight: bold;
  color: #9C125F;
}
.plan_1 {
  font-size: 1.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: #9C125F;
  padding-bottom: 0.6rem;
}

/* checked box starts here */
.checkcontainer1 {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 16px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  color: #3d3c38;
  font-weight: 500;
  border-radius: 6px;
  border: solid 1.5px #9C125F;
  padding: 3px 4px 1px 39px;
  margin-left: 0.5rem;
}

/* Hide the browser's default checkbox */
.checkcontainer1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark1 {
  position: absolute;
  bottom: 1px;
  left: 0;
  font-size: 12px;
  height: 20px;
  width: 20px;
  color: #9C125F;
  background-color: #fff;
  border: solid 1.5px #9C125F;
  border-radius: 3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  margin-left: 7px;
  margin-bottom: 3px;
}

/* On mouse-over, add a grey background color */
.checkcontainer1:hover input ~ .checkmark1 {
  background-color: #ccc;
}
.checkcontainer1 input:checked {
  background-color: #9C125F;
}
/* When the checkbox is checked, add a blue background */
.checkcontainer1 input:checked ~ .checkmark1 {
  background-color: #9C125F;
  color: #fff;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkcontainer1 input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkcontainer1 .checkmark1:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  color: white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);

  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 400px) {
  .assessquestion {
    font-size: 1em;
    padding-bottom: 1rem;
  }
  .assessrating {
    font-size: 20px;
    justify-content: flex-end;
  }
  .creative {
    font-size: 1.3em;
  }
}
.hgjs {
  background: #9C125F;
  color: #fff;
  border: none;
  width: 100%;
  font-size: 14px;
}
@media (max-width: 790px) {
  .rsliderclassPol1 {
    margin-left: 0rem;
    flex-direction: column;
  }
}

.loadingbutton {
  cursor: progress !important;
  background: #9C125F99;
}