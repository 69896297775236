@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@font-face {
  font-family: "Brfirma-Regular";
  src: local("Brfirma-Regular"),
    url("../../../Brfirma/BRFirma-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-Bold";
  src: local("Brfirma-Bold"),
    url("../../../Brfirma/BRFirma-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-BoldItalic";
  src: local("Brfirma-BoldItalic"),
    url("../../../Brfirma/BRFirma-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-ExtraLight";
  src: local("Brfirma-ExtraLight"),
    url("../../../Brfirma/BRFirma-ExtraLight.ttf") format("truetype");
}

.top-layer {
  width: 100%;
  height: 10.5px;
}
ul {
  list-style: none;
  padding-left: 0px;
  text-align: center;
}
.txtheavy {
  font-weight: 700;
}
.firstrow {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: 7%;
}
.firstrowtxt {
  font-family: "Ubuntu";
  font-size: 2.937em;
  font-stretch: normal;
  padding-top: 5rem;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #9c1258;
}
.nav-wrapper {
  display: none;
  justify-content: space-between;
  padding-left: 9rem;
  padding-right: 9rem;
  padding-top: 1rem;
}
.nav_title {
  display: flex;
  color: #150538;
  font-size: 14px;
  font-weight: 300;
}
.ftweight {
  font-weight: 500;
}
.nav_title1 {
  display: flex;
  font-size: 14px;
}
div > .title1 {
  margin: auto;
  padding-left: 1rem;
}
.title1 > .title_l {
  color: #9c1258;
}
.title1 > a .title_l {
  color: #9c1258;
}
div > .title {
  margin: auto;
  padding-left: 2rem;
}
.title_l {
  border: 0.5px solid #9c1258;
  border-radius: 3.5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  letter-spacing: normal;
  padding: 0.4rem 1rem;
  width: 97px;
  color: #9c1258;
}
.title_l:hover {
  color: inherit;
}
button:focus {
  outline: none;
}
button {
  cursor: pointer;
}
.title_t {
  border: 0.5px solid #9c1258;
  border-radius: 3.5px;
  background-color: #9c1258;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  width: 97px;
  text-align: center;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0.4rem 1rem;
}
.logo_clarity {
  position: relative;
  right: 2.2rem;
}
.Clarity-is-the-solution {
  font-size: 0.6em;
  font-style: normal;
  line-height: 1.53;
  padding-top: 1rem;
  font-family: "Ubuntu";
  letter-spacing: normal;
  text-align: left;
  color: #150538;
}
.We-are-like {
  width: 794.2px;
  height: 54.9px;
  font-size: 1.875em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #9c1258;
}
.Rectangle-8 {
  padding: 1rem;
  padding-top: 4rem;
  background-color: #f9f9f9;
}
.homebanner1 {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  border-radius: 12.8px;
}
.secondRow {
  padding: 3rem 4rem;
  padding-bottom: 7rem;
  margin-bottom: 4rem;
}
.secondimagearea {
  padding-top: 4rem;
  padding-left: 3rem;
}
.Clarity-of-Thought {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #150538;
  padding: 0rem 1rem;
}
.Clairty-lorem {
  font-size: 14px;
  font-family: "ubuntu";
  /* padding-top: 1rem; */
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #b3b3b3;
  position: relative;
  top: 1rem;
}
.clarity-card {
  text-align: center;
  width: 120px;
  margin-left: 2rem;
  margin-top: 1rem;
}
.Ellipse-4 {
  height: 71px;
  margin: auto;
  width: 53%;
}
.Ellipse-x4 {
  height: 71px;
  margin: auto;
  width: 60%;
}
.Ellipse-2 {
  /* width: 71%; */
  /* height: 80%; */
  color: #ffffff;
  background-color: #f7931e;
  border-radius: 50%;
  padding: inherit;
}
.Ellipse-3 {
  color: #ffffff;
  border-radius: 50%;
  padding: 16px 13px;
}
.Ellipse__1 {
  color: #ffffff;
  border-radius: 50%;
  padding: 16px 8px;
}
.cards-section1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}
.borderc {
  padding: 26px 19px;
}
.straight_line {
  border-left: 1px dotted;
}
.How-it-works {
  height: 52px;
  font-size: 1.875em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #9c1258;
}
.homebanner3 {
  width: 90%;
  height: 90%;
  border-radius: 16.2px;
}
.homebanner5 {
  width: 100%;
  z-index: 2;
}

.thirdrow {
  padding-left: 2rem;
  padding-bottom: 4rem;
}
.oneimg {
  width: 100%;
}
.Assess {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  font-weight: 400;
  letter-spacing: normal;
  text-align: left;
  color: #9c1258;
}
.The-best-way {
  padding-top: 0.7rem;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #2c2147;
  padding-left: 3.5rem;
}
.howcardwrapper {
  margin-bottom: 2rem;
}
.fourthsecrow {
  background-color: #9c1258;
  flex-wrap: wrap;
  margin-top: 4rem;
}
.I-found {
  font-size: 1.5em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 4rem 1rem 0rem 1rem;
  margin-left: 2rem;
}
.--Marta-Vaughn {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-left: 3rem;
  padding-top: 0.8rem;
  padding-bottom: 1.1rem;
}
.readmore {
  font-size: 14px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  font-family: "ubuntu";
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  background-color: #9c1258;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 3px;
}
.Knowing-yourself {
  font-size: 1.875em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: center;
  color: #9c1258;
}

.TAKE-FREE-ASSESSMENT {
  font-size: 16px;
  background-color: #f7931e;
  padding: 1rem 2rem;
  border: 0px solid transparent;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
}

.fifthsecrow {
  margin-top: 5rem;
  padding-top: 2rem;
  margin-bottom: 4rem;
}
.takeAssesment {
  margin-top: 2rem;
}
.footer_tit {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #2c2147;
  text-align: left;
}
.About-Us {
  font-family: "ubuntu";
  font-size: 14px;
  padding-top: 1rem;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #0f1a29;
}
.footer_first_wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.iconwrapper {
  margin-top: 2rem;
  padding-left: 6rem;
}
.iconimage {
  width: 1.875em;
}
.reserved {
  font-family: "ubuntu";
  font-size: 14px;
  padding-top: 1rem;
  margin-left: 6rem;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #0f1a29;
  padding-bottom: 2rem;
}
.circle {
  height: 20px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
}
.listwraperMob a {
  color: white;
}
@media (min-width: 780px) {
  .top-layer {
    background-color: #fff;
  }
  .clarity_logo {
    display: none;
  }
  .tymes {
    transform: rotateY(180deg);
  }
}
@media (min-width: 780px) {
  .nav-wrapper {
    display: flex;
  }
  .hamburger {
    padding-right: 0.5rem;
  }
  .clarity_logo {
    padding-top: 0.2rem;
    padding-left: 0.5rem;
  }
  .top-layer {
    background-color: #9c1258;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
  }
  #wrapper > div {
    display: inline-block;
  }
  .hamburgerwrap {
    display: none;
  }
  .lakk {
    display: none;
  }
  /* fix the navbar at the top */
  .NavsectionFixed {
    position: fixed;
    background: #ffffffd8;
    z-index: 1;
    width: 100%;
    top: 0px;
  }
}
.firstassesbtn {
  font-size: 0.38em;
  letter-spacing: normal;
  width: 200px;
  color: #ffffff;
  cursor: pointer;
  background-color: #9c1258;
  padding: 0.6rem 0.7rem;
  border-radius: 10px;
  margin-top: 1rem;
  text-align: center;
}
.homebanner0 {
  width: 100%;
}
.isloadd1 {
  text-align: center;
}
.text-teala {
  color: #58575a;
  font-size: italic;
  text-shadow: rgb(180, 177, 172);
  font-weight: 400;
}
@media (max-width: 579px) {
  .fittt {
    width: 100%;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
  .imgCart {
    min-width: 40px !important;
  }
  .newcart {
    min-width: 35px !important;
  }
  .listwraperMob {
    font-size: 16px !important;
  }
  .TAKE-FREE-ASSESSMENT {
    padding: 0.5rem 1rem;
  }
  .footer_tit {
    font-size: 15px;
  }
  .About-Us {
    font-size: 12px;
  }
  .firstrow {
    padding-top: 1rem;
    margin-left: 0%;
  }
  .--Marta-Vaughn {
    margin-left: 0px;
  }
  .I-found {
    margin-left: 0px;
  }
  .firstassesbtn {
    font-size: 14px;
  }
  .Clarity-is-the-solution {
    font-size: 17px;
  }
  #wrapper > div {
    display: none;
  }
  .We-are-like {
    font-size: 1.4em;
  }
  .firstrowtxt {
    font-size: 1.4em !important;
  }
  .listwraperMob a {
    font-size: 19px;
  }
  .Knowing-yourself {
    font-size: 1.2em;
  }
  .TAKE-FREE-ASSESSMENT {
    font-size: 14px;
  }
  .listwraper {
    padding-top: 0px;
  }
  .shii11 {
    padding-top: 0px;
  }
  .How-it-works {
    font-size: 1.7em;
    text-align: center;
  }
  .I-found {
    font-size: 14px;
  }
  .cards-section1 {
    position: relative;
    right: 1rem;
  }
  .reserved {
    margin-left: 1rem;
  }
}
#content {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  top: 50%;
  z-index: 5;
}
@media (max-width: 360px) {
  .fittt1 {
    width: 100%;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 310px) {
  .line-top {
    display: none;
  }
  .cards-section1 {
    justify-content: center;
  }
}
@media (max-width: 779px) {
  .homebanner5 {
    bottom: -1.5rem;
    left: 1rem;
  }
  .Toastify__toast {
    width: 69%;
  }
  .Toastify__toast-container {
    text-align: -webkit-center;
  }
  .secondoso {
    margin-top: 1rem;
  }
  .flexsss {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.4rem;
    padding-right: 1rem;
    background: white;
    padding-left: 0.5rem;
    padding-bottom: 0.8rem;
  }

  .I-found {
    padding: 1rem 0rem 0rem 0rem;
  }
  .top-layer {
    height: 53.5px;
  }
  .iconwrapper {
    margin-top: 2rem;
    padding-left: 0rem;
    text-align: center;
  }
  .firstrowtxt {
    padding-top: 2rem;
  }
  .secondRow {
    padding: 3rem 0rem;
  }
  .secondimagearea {
    padding-left: 1rem;
  }
  .clarity-card {
    margin-left: 1rem;
  }
  .thirdrow {
    margin-left: 0px;
    padding-left: 1px;
  }
  .secondline {
    display: none;
  }
  .padleftabout {
    padding-left: 1rem;
  }
  .shii11 {
    padding-top: 0rem;
  }
}
.title_ll {
  background-color: #9c1258;
  color: white;
  border: none;
  border-radius: 34px;
  padding: 0.4rem 0.9rem;
}

.useravatar {
  border-radius: 50%;
  border: 1px solid #f7f1f4;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.useravatarwraper {
  border-radius: 50%;
  border: 1px solid #9c1258;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: table;
}
.line-top {
  width: 100%;
  /* height: 60px; */
  z-index: -1;
  background: transparent;
  border: none;
  border-top: dashed 1px #b3b3b3;
  padding: 61px 141%;
  border-radius: 58%;
  margin: -86px 76px 51px 34px;
}
@media (min-width: 1500px) {
  .line-top {
    margin: -86px 76px 51px 51px;
  }
}
.stepgood {
  width: 41.43px;
  min-width: 41.43px;
  max-width: 41.43px;
  padding: 0px;
  margin-right: 1rem;
}
.steptwo {
  width: 41.43px;
  min-width: 41.43px;
  max-width: 41.43px;
  padding: 0px;
  margin-right: 1rem;
}
/* .straight_line{
  border-left: 2px dotted gray;
  padding: 66px;
  position: relative;
  left: 2.2rem;
  z-index: -1;
} */
.line2 {
  width: 30px;
  background: #9c1258;
  height: 3px;
  margin-top: 5px;
}
.line1 {
  width: 30px;
  background: #9c1258;
  height: 3px;
  margin-top: 0px;
}
.listwraper {
  color: white;
  font-size: 25px;
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.listwraperMob {
  font-size: 25px;
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
.navmobbtn {
  border: 1px solid #9c1258;
  padding: 0.7rem 2rem;
  border-radius: 2.5rem;
}
.shiftlefff {
  transition: transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}
.shiftlefff:hover {
  transform: scale(1.05);
}

.line1active {
  transform: rotateZ(206deg);
}
.firstImg {
  max-width: 270px;
  border-radius: 15px;
}
.firstImg1 {
  max-width: 270px;
  height: 100%;
  border-radius: 15px;
}
.secondoso {
  max-height: 413.27px;
  max-width: 270px;
  min-width: 270px;
  margin-right: 1rem;
  text-align: center;
  border-radius: 20px;
  background-color: #f8f8f8;
  margin-left: 5px;
}
.takecharge {
  text-align: center;
  font-weight: bolder;
  position: relative;
  bottom: 2.7rem;
  line-height: 1.2;
}
.shiftlefff .title_t {
  margin-left: 2rem;
}
.inner21 {
  background-color: orange;
  width: 60px;
  text-align: center;
  color: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid #f9f9f9;
  font-size: 12px;
  font-weight: 500;
}
.wraper22 {
  width: 60px;
  color: white;
  height: 60px;
  display: table;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0.3rem;
  border: 0.1px solid #b3b3b3;
  z-index: 3;
  position: relative;
}

/*Landing Page Redesign */
.nav_margin {
  margin: 0;
}
.redoNav {
  position: fixed;
  z-index: 99;
  background: #23304c;
  color: #ffffff;
  width: 100vw;
  padding-top: 1rem;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-bottom: 1rem;
  flex-wrap: nowrap;
  transition: 0.5s;
  box-shadow: 0 4px 2px -2px rgba(35, 48, 76, 30%);
}
.nav_titlenew {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  font-family: "Brfirma-Regular";
  letter-spacing: 0.3px;
}
.hhome {
  font-family: "Brfirma-Bold";
}
.homecontainer {
  padding: 0;
}
.newfirstrow {
  background-image: url("../../../assets/herobackdrop11.svg");
  background-size: cover;
  margin-left: -18px;
  margin-right: -17px;
}
.jaiyeimg,
.mobilebg,
.ellipse1,
.ellipse2 {
  display: none;
}
.hometext {
  color: #ffffff;
  margin-top: 13rem;
  font-size: 42px;
  margin-bottom: 15rem;
  padding-left: 4%;
  padding-right: 5%;
  font-family: "Brfirma-Bold";
}
.smhometext {
  font-size: 22px;
  font-family: 'Brfirma-Regular';
  font-weight: 500;
  margin-top: 1rem;
}
.socials {
  display: flex;
  flex-direction: column;
  margin-top: 19rem;
  justify-content: space-around;
  align-items: center;
}
.socials1 {
  width: max-content;
  padding-bottom: 2.5rem;
}
.ssmedia {
  cursor: pointer;
}
.signupbtn {
  padding: 0.6rem 1rem;
  width: 110px;
  font-size: 15px;
  border-radius: 5px;
}
.hstarted {
  color: #fff;
  background: #9c1258;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 15px;
  width: 190px;
  text-align: center;
  cursor: pointer;
}
.newsecondrow {
  background: #ffffff;
  padding: 3rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
}
.secondtitle {
  color: #18214d;
  font-family: "Brfirma-Bold";
  font-size: 35px;
  margin: 0rem auto;
  text-align: center;
}
.secondtitle1 {
  color: #ffffff;
  padding-left: 0;
  z-index: 1;
  position: relative;
  background-image: url("../../../assets/Landingrectangle.png");
  background-size: contain;
  padding-bottom: 20px;
  padding-top: 0px;
  padding-right: 2.5rem;
  padding-left: 5px;
  background-repeat: no-repeat;
  display: inline-flex;
}
.trustttl {
  padding-top: 5px;
}
.clientttl {
  padding-top: 12px;
  padding-bottom: 12px;
}
.secondimg {
  position: absolute;
  /* right: -46px; */
  left: -4px;
  top: -10px;
  z-index: -1;
  width: 150px;
  height: 68px;
  display: none;
}
.crazycircle1 {
  display: flex;
}
.crazycircle1 .lcircle1 {
  width: 30vw;
  margin-top: 10%;
}
.crazycircle1 .larrow1 {
  width: 35vw;
  margin-top: -13%;
  margin-left: -2.5%;
}
.crazycircle1 .lcircle2 {
  width: 30vw;
  margin-top: -4%;
}
.crazycircle2 {
  display: flex;
  width: 100%;
  margin-top: -13%;
}
.crazycircle2 .larrow2 {
  width: 5vw;
  margin-left: 7.5%;
}
.crazycircle2 .lcircle3 {
  width: 42vw;
  margin-left: 19%;
}
.crazycircle2 .larrow3 {
  width: 23vw;
  margin-top: -22%;
  margin-left: -7%;
}
.crazycircle3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -13%;
}
.crazycircle3 .lcircle4 {
  width: 44vw;
  margin-left: -2%;
}
.crazycircle3 .lcircle5 {
  width: 40vw;
  margin-right: -5%;
}
.crazycircle4 {
  display: flex;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
.crazycircle4 .larrow4 {
  width: 13vw;
  margin-top: -22%;
}
.crazycircle4 .lcircle6 {
  width: 33vw;
  margin-left: 9.5%;
  margin-top: -5%;
}
.crazycircle4 .larrow5 {
  width: 23vw;
  margin-top: -32%;
  margin-left: -5%;
}
.crazycircle3 .larrow6 {
  margin-right: -35%;
  margin-top: -35%;
  width: 7vw;
}
.crazycircle1 .smarrow1,
.crazycircle2 .smarrow2,
.crazycircle3 .smarrow4,
.crazycircle2 .smcircle1,
.crazycircle4 .smarrow5,
.crazycircle4 .smcircle2 {
  display: none;
}
.newthirdrow {
  background: #23304c;
  font-family: "Brfirma-Regular";
  text-align: center;
  padding: 3rem;
  padding-top: 4rem;
}
.newthirdrowtext {
  color: #ffffff;
  font-size: 19px;
  margin: 0 17%;
  letter-spacing: 0.2px;
  line-height: 30px;
}
.newthirdrowbtn {
  color: #23304c;
  background: #ffffff;
  width: 200px;
  padding: 1rem;
  border-radius: 7px;
  margin: auto;
  margin-top: 2rem;
  font-family: "Brfirma-Bold";
  font-size: 14px;
  cursor: pointer;
}
.thirdgetstarted {
  background-image: url("./../../../assets/homecardbg.svg");
  padding: 8rem 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  font-family: "Brfirma-Regular";
  justify-content: space-evenly;
  padding: 9.5rem 0 5rem 0;
  background-size: cover;
}
.thirdgetstartedcard {
  text-align: center;
  padding: 2rem 2.5rem;
  border-radius: 6px;
}
.thirdgthead {
  font-weight: 600;
  color: #18214d;
  font-size: 18px;
  letter-spacing: 0.5px;
  margin: auto;
  width: 200px;
  margin-bottom: 0.8rem;
}
.thirdgttxt {
  color: #92969d;
  font-size: 13px;
  line-height: 20px;
  width: 200px;
  margin: auto;
  margin-bottom: 1.5rem;
}
.thirdgtbtn1 {
  border: 1px solid #1bb978;
  background: #1bb978;
  border-radius: 5px;
  font-size: 13px;
  padding: 0.8rem 1rem;
  width: 150px;
  margin: auto;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
}
.thirdgtbtn2 {
  border: 1px solid #1bb978;
  color: #1bb978;
  border-radius: 5px;
  font-size: 13px;
  padding: 0.8rem 1rem;
  width: 150px;
  margin: auto;
  cursor: pointer;
  font-weight: 600;
}
.thirdgtbtn3 {
  border: 1px solid #a5a5a5;
  color: #a5a5a5;
  border-radius: 5px;
  font-size: 13px;
  padding: 0.8rem 1rem;
  width: 150px;
  margin: auto;
  cursor: pointer;
  font-weight: 600;
}
.gtclose {
  position: absolute;
  z-index: 2;
  margin-top: -6.5rem;
  margin-left: 39.5%;
  width: 30px;
  cursor: pointer;
}
.newfourthrow {
  background: #ffffff;
  padding: 2rem;
  flex-direction: column;
}
.fourthttl {
  font-size: 33px;
}
.fourthimg {
  right: -68px;
}
.fourthline {
  border: 2px solid #9c1258;
  margin: auto;
  width: 170px;
}
.fourthtext {
  text-align: center;
  font-family: "Brfirma-Regular";
  margin-top: 1.5rem;
  letter-spacing: 0.4px;
  margin-bottom: 2rem;
}
.fourthsec {
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem;
}
.fourthsec1 {
  margin-top: -1rem;
}
.fourthsubsec {
  text-align: center;
  width: 290px;
}
.fourthsubhead {
  font-family: "Brfirma-Bold";
  font-size: 14px;
  letter-spacing: 0.3px;
  position: relative;
  z-index: 10;
}
.fourthsubtext {
  color: #666666;
  font-family: "BRFirma-Regular";
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-top: 0.7rem;
  position: relative;
  z-index: 10;
}
.homelaptop {
  position: absolute;
  width: 100%;
}
.homelaptop1 {
  position: relative;
  text-align: center;
  z-index: 10;
  width: 100%;
  top: 12.5rem;
  left: -1rem;
}
.laptop {
  width: 49%;
}
.fourthposit {
  margin: auto;
  margin-bottom: 3rem;
  margin-top: -4.6rem
}
.trustimg {
  text-align: center;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 10;
}
.trustimgin {
  background: #17ddea;
  border-radius: 50%;
  padding: 0.875rem;
}
.border1 {
  position: absolute;
  margin-top: 2rem;
  margin-left: 2rem;
  z-index: 0;
}
.border2 {
  position: absolute;
  margin-top: 2rem;
  height: 200px;
  width: 550px;
  margin-left: -22.5rem;
}
.border3 {
  position: absolute;
  margin-top: -3rem;
  margin-left: -9rem;
  height: 80px;
  width: 430px;
}
.border4 {
  position: absolute;
  margin-top: 2rem;
  height: 210px;
  width: 488px;
  margin-left: -18.4rem;
}
.border5 {
  position: absolute;
  margin-top: 1.75rem;
  margin-left: 2rem;
  width: 280px;
}
.leftborder,
.rightborder {
  display: none;
}
.frheight {
  height: 133px;
}
/*Hear From Clients*/
.homemap {
  background-color: #23304c;
  background-image: url("../../../assets/map.svg");
  background-size: cover;
  padding: 3rem;
}
.mapmobile {
  display: none;
}
.homemaptitle {
  margin: 0;
  padding-left: 1rem;
  color: #ffffff;
  margin-bottom: 2rem;
}
.homemapimg {
  right: -1.6rem;
}
.clienttext {
  color: #ffffff;
  font-family: "BRFirma-Regular";
  font-style: italic;
  font-size: 13px;
  margin-left: 2rem;
  margin-right: 5rem;
  width: 300px;
  line-height: 25px;
  letter-spacing: 0.3px;
}
.mapname {
  margin-top: 0.5rem;
  font-family: 'Brfirma-Bold';
  letter-spacing: 0.5px;
}
.clientimg {
  height: 84px;
  width: 84px;
}
.homemapclient {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  padding-bottom: 0.5rem;
  margin: auto;
}
.homemapsec {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  width: 100%;
}
.homemapsubsec {
  display: flex;
  margin-bottom: 2rem;
}
.homemapline {
  width: 100%;
  border: 0.5px solid #ffffff;
}
.homemapbtmtxt {
  color: #ffffff;
  margin: auto;
  margin-top: 2rem;
  font-size: 21px;
  letter-spacing: 0.3px;
  font-family: "Brfirma-Regular";
}
/*Think This Is You*/
.homethink {
  background: #0c9fb3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0.5rem;
}
.homethinktxt {
  font-family: "Luckiest Guy", cursive;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  letter-spacing: 1px;
}
.homethinkbtn {
  color: #0c9fb3;
  background: #ffffff;
  text-align: center;
  width: 170px;
  font-family: "Brfirma-Regular";
  font-weight: 600;
  padding: 0.8rem 1rem;
  border-radius: 7px;
  font-size: 13.5px;
  margin-top: 2rem;
  cursor: pointer;
}
.takefree {
  display: block;
  text-align: center;
}

/*Footer*/
.home_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 3rem 0.5rem 2rem 0.5rem;
  width: 100vw;
}
.footer_stay {
  font-family: "Brfirma-Bold";
  color: #001833;
  font-size: 28px;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
.footer_signup {
  color: #001833;
  font-family: "Brfirma-Regular";
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  font-size: 18px;
  margin-bottom: 1rem;
}
.footer_maill {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 510px;
}
.footer_maill > label {
  flex: 100%;
  font-weight: 600;
}
.footer_maill > label > p {
  padding-left: 1rem;
  margin-bottom: 0;
}
.footer_mail {
  height: 50px;
  width: 350px;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  border: 1px solid #999999 !important;
  font-family: "Brfirma-Regular";
  padding-left: 2rem;
  font-size: 13.5px;
  letter-spacing: 0.6px;
  outline: none;
}
.footer_mail::placeholder {
  font-size: 13px;
  color: #666666;
  letter-spacing: 0.2px;
}
.footer_btn {
  height: 50px;
  width: 160px;
  border-radius: 0px 100px 100px 0px;
  background: #9c1258;
  color: #ffffff;
  font-family: "Brfirma-Bold";
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.footer_socials {
  margin: 1.5rem 0;
  width: 550px;
  display: flex;
  justify-content: space-evenly;
}
.footer_social_1 {
  width: 12px;
}
.footer_social_2 {
  width: 30px;
}
.footer_social_3 {
  width: 28px;
}
.footer_social_4 {
  width: 28px;
}
.ftrnav {
  font-family: "Brfirma-Regular";
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 600px;
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0;
  margin-top: 3.5rem;
}
.ftrpolicy {
  font-family: "Brfirma-Regular";
  color: #666666;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
}
.ftrterms::after {
  content: " ";
  display: inline-block;
  border: 1px solid #666666;
  height: 30px;
  position: relative;
  top: 0.5rem;
  margin: 0 1rem;
}
.ftrline {
  border: 0.5px solid #666666;
  width: 90%;
  margin: 0;
}
.ftrcpy {
  font-family: "Brfirma-Regular";
  color: #666666;
  font-size: 15px;
  letter-spacing: 0.5px;
  margin-top: 1.5rem;
}
.privacynav {
  background: #ffffff;
  color: #000000;
}

/*About Us*/
.aboutfirstsec {
  background-image: url(../../../assets/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  padding: 6rem;
  padding-left: 5rem;
  margin-left: auto;
  margin-right: auto;
}
.aboutfirsthead {
  font-family: "Brfirma-Bold";
  font-size: 42px;
  letter-spacing: 1px;
  margin-top: 12rem;
  margin-bottom: 1.5rem;
}
.aboutfirsttxt {
  font-family: "Brfirma-Regular";
  margin-right: 36%;
  font-size: 15px;
  letter-spacing: 0.7px;
  line-height: 25px;
  margin-bottom: 6rem;
}
.withclaritytxt {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  padding-left: 4rem;
}
.abtwithclarityrow {
  margin: auto;
  background: #f8f8f8;
  padding-bottom: 6rem;
}
.abtwithclarity {
  color: #001833;
  font-size: 28px;
  width: 100%;
  margin: 8rem 0 2rem 5rem;
  font-weight: 600;
}
.abttick {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 650px;
}
.abtticktxt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 670px;
  padding-left: 2.5rem;
  width: 400px;
}
.yearlong1 {
  margin-top: 0.7rem;
}
.yearlong2 {
  margin-top: 1.5rem;
}
.yearlong3 {
  margin-top: 1rem;
}
.withclarityimg {
  display: flex;
  justify-content: center;
}
.profimg1 {
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
  align-self: center;
  margin-right: 2rem;
  height: 580px;
  width: 38%;
  justify-content: space-evenly;
}
.profimg2 {
  display: flex;
  flex-direction: column;
  height: 680px;
  width: 38%;
  justify-content: space-evenly;
}
.profimg1b {
  align-self: flex-end;
}
.profimg2a {
  align-self: flex-start;
}
.tickdash {
  border: 1px dashed #666666;
  transform: rotate(90deg);
  width: 60px;
  margin-left: -0.8rem;
}
.tickdash1 {
  width: 34px;
}
.abthapprod {
  margin: auto;
  padding: 3rem 5rem;
  padding-bottom: 9rem;
}
.abtprod {
  font-family: "Brfirma-Bold";
  color: #001833;
  font-size: 20px;
  margin: 1.5rem auto;
  text-align: center;
}
.abtprodsec {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
}
.prodsec1 {
  background: #d8f9da;
  border-radius: 8px 8px 0px 8px;
  flex: 50%;
  text-align: center;
  padding: 3rem;
}
.prodsec2 {
  flex: 50%;
  font-family: "Brfirma-Regular";
  padding: 3rem;
  font-size: 15px;
}
.for1 {
  font-family: "Brfirma-Bold";
  margin-bottom: 3rem;
  font-size: 17px;
}
.prodindi {
  color: #ffffff;
  letter-spacing: 0.5px;
  padding-left: 0.3rem;
  position: relative;
  background-image: url("../../../assets/Landingrectangle.png");
  background-size: contain;
  padding-right: 1.5rem;
  padding-right: 2.5rem;
  padding-top: 15px;
  padding-bottom: 14px;
  padding-left: 3px;
  background-repeat: no-repeat;
}
.prodteam {
  padding-top: 8px;
  padding-bottom: 8px;
}
.indibox {
  z-index: -1;
  width: 115px;
  position: absolute;
  left: 0rem;
  top: -5px;
  display: none;
}
.prodgreentick {
  margin-right: 3rem;
  margin-left: 3rem;
  height: 28px;
}
.indilist {
  margin-bottom: 1.5rem;
  display: flex;
}
.indibox1 {
  width: 80px;
}
.prodsec4 {
  background: #d8f9da;
  border-radius: 8px 8px 0px 8px;
  flex: 50%;
  width: 50%;
}
.sec4img {
  width: 100%;
  padding-top: 5rem;
}
.grntick {
  margin-left: 1rem;
}
.newlogin {
  font-size: 14px;
  height: 40px;
}

/*Redesign Mobile Responsiveness*/

@media (max-width: 1200px) {
  .homelaptop,
  .border1,
  .border2,
  .border3,
  .border4,
  .border5 {
    display: none;
  }
  .fourthsec {
    padding: 2rem 10%;
  }
  .fourthsubsec {
    border: 1px dashed #666666;
    border-radius: 45%;
    padding: 2rem 3rem;
    width: 355px;
  }
  .newfirstrow {
    background-image: url("../../../assets/mobilebackdrop.svg");
  }
  .home1 {
    padding-top: 3rem;
  }
  .hometext {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    margin-top: 15rem;
  }
  .smhometext {
    font-size: 22px;
  }
  .jaiyeimg {
    display: block;
    width: 30%;
    position: relative;
    top: 10rem;
    left: 60%;
  }
  .mobilebg {
    display: block;
    width: 80%;
  }
  .frheight {
    height: auto;
  }
}

@media (max-width: 1000px) {
  .newthirdrowtext {
    font-size: 16px;
    line-height: 26px;
    margin: 0 12%;
  }
  .secondtitle {
    font-size: 30px;
  }
  .secondimg {
    /* right: -60px; */
    height: 55px;
    top: -7px;
  }
  .fourthsec {
    padding: 2rem 3%;
  }
  .fourthttl {
    font-size: 28px;
  }
  .fourthimg {
    right: -50px;
    width: 120px;
  }
  .fourthline {
    width: 140px;
  }
  .fourthtext {
    font-size: 13px;
  }
  .fourthsubsec {
    width: 335px;
  }
  .trustimg {
    width: 50px;
  }
  .fourthsubhead {
    font-size: 13px;
  }
  .fourthsubtext {
    font-size: 12px;
  }
  .fourthposit {
    margin-top: 0rem;
  }
  .thirdgetstarted {
    flex-direction: column;
  }
  .thirdgetstartedcard {
    width: 300px;
    margin: auto;
    margin-bottom: 4rem;
  }
  .gtclose {
    position: absolute;
    margin-top: -34rem;
    margin-left: 90%;
    width: 25px;
  }
}

@media (max-width: 900px) {
  .mobilebg,
  .socials {
    display: none;
  }
  .hometext {
    order: 1;
    padding-left: 4rem;
    flex: 0 0 90%;
    max-width: 90%;
    margin-bottom: 0;
    padding-right: 25%;
    margin-top: 13rem;
    font-size: 35px;
  }
  .smhometext {
    font-size: 19px;
  }
  .home1 {
    order: 2;
    margin-left: 3rem;
    margin-bottom: 10rem;
  }
  .jaiyeimg {
    top: 0;
    left: 0;
    width: 60%;
  }
}

@media (max-width: 800px) {
  .secondtitle {
    font-size: 28px;
  }
  .secondtitle1 {
    padding-top: 0px;
    padding-bottom: 5px;
    padding-right: 1.5rem;
  }
  .trustttl {
    padding-right: 1.5rem;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .secondimg {
    /* right: -48px; */
    width: 130px;
  }
  .crazycircle1,
  .crazycircle2,
  .crazycircle3,
  .crazycircle4 {
    flex-direction: column;
    width: 100%;
  }
  .crazycircle1 .larrow1,
  .crazycircle2 .larrow2,
  .crazycircle2 .larrow3,
  .crazycircle2 .lcircle3,
  .crazycircle4 .larrow4,
  .crazycircle4 .larrow5,
  .crazycircle4 .lcircle6 {
    display: none;
  }
  .crazycircle1 .smarrow1,
  .crazycircle2 .smarrow2,
  .crazycircle3 .smarrow4,
  .crazycircle2 .smcircle1,
  .crazycircle4 .smarrow5,
  .crazycircle4 .smcircle2 {
    display: inline-flex;
  }
  .crazycircle1 .lcircle1,
  .crazycircle1 .lcircle2,
  .crazycircle2 .lcircle3,
  .crazycircle3 .lcircle4,
  .crazycircle3 .lcircle5,
  .crazycircle4 .lcircle6 {
    width: auto;
    margin: 0;
  }
  .crazycircle1 {
    margin-top: 2rem;
  }
  .crazycircle1 .lcircle1 {
    align-self: flex-start;
    width: 65%;
  }
  .crazycircle1 .lcircle2 {
    align-self: flex-end;
    width: 65%;
  }
  .crazycircle1 .smarrow1 {
    align-self: flex-end;
    margin-right: 20%;
    margin-top: -10%;
    margin-bottom: 2rem;
    transform: rotate(-30deg);
    width: 25%;
  }
  .crazycircle2 {
    margin-top: 0;
  }
  .crazycircle2 .smarrow2 {
    margin-left: auto;
    margin-right: 22%;
    width: 20%;
  }
  .crazycircle2 .smcircle1 {
    align-self: flex-start;
    width: 70%;
    margin-top: -5rem;
  }
  .crazycircle3 .larrow6 {
    order: 1;
    width: 12vw;
    margin: auto;
    margin-right: 35%;
    margin-top: 4rem;
  }
  .crazycircle3 .lcircle5 {
    order: 2;
    align-self: flex-end;
    margin-right: -10%;
    width: 80%;
  }
  .crazycircle3 .smarrow4 {
    order: 3;
    width: 20%;
    margin-top: -28%;
    margin-left: 20%;
    align-self: flex-start;
  }
  .crazycircle3 .lcircle4 {
    align-self: flex-start;
    width: 80%;
    order: 4;
    margin-left: -10%;
    margin-right: auto;
  }
  .crazycircle4 {
    flex-direction: row;
  }
  .crazycircle4 .smarrow5 {
    align-self: flex-start;
    margin-left: 10%;
    margin-right: auto;
    width: 25%;
  }
  .crazycircle4 .smcircle2 {
    width: 60%;
  }
  .fourthttl {
    font-size: 26px;
  }
  .fourthimg {
    right: -35px;
    top: -5px;
    width: 100px;
    height: 45px;
  }
  .fourthline {
    width: 120px;
  }
  .fourthsubsec {
    border: none;
    width: 330px;
  }
  .fourthsec {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .leftborder,
  .rightborder {
    display: block;
    position: absolute;
    margin-top: 1.5rem;
  }
  .rightborder {
    transform: rotate(180deg);
    margin-left: 8rem;
    width: 185px;
    height: 284.4px;
  }
  .leftborder {
    margin-left: -3rem;
  }
  .leftborder1 {
    margin-top: 1.7rem;
    height: 227px;
  }
  .rightborder2 {
    height: 280px;
    width: 180px;
  }
  .leftborder2 {
    height: 230px;
  }
  .fourthposit {
    padding-top: 1rem;
  }
}

@media (max-width: 767px) {
  .jaiyeimg {
    width: 45%;
  }
  .hometext {
    padding-right: 10%;
    margin-top: 10rem;
  }
  .home1 {
    margin-bottom: 8rem;
  }
  .ellipse1 {
    display: block;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 8rem;
    right: 1rem;
  }
  .ellipse2 {
    display: block;
    position: absolute;
    right: 2rem;
    top: 13rem;
    width: 46%;
  }
}

@media (max-width: 600px) {
  .newthirdrowtext {
    font-size: 15px;
    margin: 0;
  }
  .secondtitle {
    font-size: 24px;
  }
  .secondimg {
    width: 105px;
    height: 45px;
    /* right: -33px; */
  }
  .fourthimg {
    width: 90px;
    right: -30px;
  }
  .fourthline {
    width: 100px;
  }
  .hometext {
    padding-right: 1rem;
    padding-left: 2rem;
  }
  .home1 {
    margin-left: 1rem;
    margin-bottom: 8rem;
  }
  .ellipse2 {
    width: 50%;
    right: 1.5rem;
    top: 12.5rem;
  }
  .thirdgetstarted {
    padding: 5rem 0 0rem 0;
  }
  .gtclose {
    margin-top: -32rem;
    width: 20px;
  }
}

@media (max-width: 550px) {
  .newfirstrow {
    background-image: url("../../../assets/mobilebg2.svg");
  }
  .ellipse1, .ellipse2 {
    display: none;
  }
}
@media (max-width: 400px) {
  .newthirdrow {
    padding: 3rem 2rem 4rem 2rem;
  }
  .newthirdrowtext {
    font-size: 14px;
    line-height: 24px;
  }
  .newthirdrowbtn {
    width: 190px;
  }
  .secondimg {
    width: 85px;
    /* right: -14px; */
  }
  .fourthttl {
    font-size: 20px;
  }
  .fourthimg {
    right: -15px;
    width: 65px;
    height: 35px;
    top: -4px;
  }
  .newfourthrow {
    padding: 2rem 0.5rem;
  }
  .fourthtext {
    font-size: 12px;
  }
  .rightborder {
    margin-left: 5.8rem;
    width: 170px;
  }
  .rightborder2 {
    margin-left: 6rem;
  }
  .leftborder {
    margin-left: -3rem;
  }
  .leftborder1 {
    margin-left: -2rem;
  }
  .leftborder2 {
    width: 170px;
    margin-left: -2rem;
  }
  .hometext {
    font-size: 30px;
    margin-top: 8rem;
  }
  .smhometext {
    font-size: 18px;
  }
  .ellipse1 {
    width: 40px;
    height: 40px;
    right: 1rem;
  }
  .ellipse2 {
    width: 80%;
    height: 24rem;
    right: 1rem;
    top: 11rem;
  }
  .thirdgetstartedcard {
    width: 80%;
    margin-bottom: 4rem;
    padding: 8%;
    text-align: center;
  }
  .thirdgthead {
    font-size: 17px;
  }
  .gtclose {
    margin-top: -31rem;
    width: 18px;
    margin-left: 87%;
  }
  .newsecondrow {
    padding: 1rem;
  }
  .crazycircle1 .lcircle1, .crazycircle1 .lcircle2 {
    width: 75%;
  }
  .crazycircle2 .smcircle1 {
    width: 70%;
  }
  .crazycircle3 .larrow6 {
    margin-right: 40%;
  }
  .crazycircle4 .smarrow5 {
    margin-left: 7%;
  }
  .crazycircle4 .smcircle2 {
    width: 68%;
  }
  .crazycircle3 .lcircle5, .crazycircle3 .lcircle4 {
    width: 95%;
  }
}

/*Footer Responsiveness*/
@media (max-width: 1000px) {
  .footer_stay {
    font-size: 26px;
  }
  .footer_signup {
    font-size: 16px;
  }
  .footer_maill {
    width: 380px;
  }
  .footer_mail {
    height: 45px;
    width: 280px;
    outline: none;
  }
  .footer_btn {
    height: 45px;
    width: 100px;
    font-size: 14px;
  }
  .footer_socials {
    width: 400px;
  }
  .footer_social_1 {
    width: 10px;
    height: 20px;
  }
  .footer_social_2,
  .footer_social_3,
  .footer_social_4 {
    width: 20px;
    height: 20px;
  }
  .ftrnav {
    font-size: 13px;
    width: 400px;
  }
  .ftrpolicy {
    font-size: 13px;
    margin-left: -1rem;
  }
  .ftrterms::after {
    height: 24px;
  }
  .ftrcpy {
    font-size: 13px;
  }
}

@media (max-width: 800px) {
  .footer_stay {
    font-size: 24px;
  }
  .footer_signup {
    font-size: 14px;
  }
  .footer_maill {
    width: 400px;
    margin: 2rem auto;
    position: relative;
  }
  .footer_maill > label > p {
    font-size: 14px;
  }
  .footer_mail {
    border-radius: 100px;
    height: 55px;
    width: 400px;
  }
  .footer_btn {
    border-radius: 100px;
    position: absolute;
    margin-top: 1.6rem;
    left: 18.4rem;
  }
  .footer_socials {
    width: 350px;
  }
  .ftrnav {
    flex-direction: column;
    width: auto;
    height: 180px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ftrpolicy {
    margin-bottom: 0.5rem;
  }
  .ftrrights {
    display: block;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .footer_maill {
    width: 300px;
  }
  .footer_mail {
    width: 300px;
  }
  .footer_btn {
    margin-top: 1.6rem;
    left: 12.2rem;
  }
}

@media (max-width: 400px) {
  .footer_maill {
    width: 260px;
  }
  .footer_mail {
    width: 260px;
  }
  .footer_btn {
    left: 9.7rem;
  }
  .footer_socials {
    width: 90%;
  }
}

/*Think This Is You Responsiveness*/
@media (max-width: 1000px) {
  .homethinktxt {
    font-size: 26px;
  }
}
@media (max-width: 800px) {
  .homethinktxt {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  .homethinktxt {
    font-size: 20px;
  }
  .helpfix {
    display: block;
    text-align: center;
  }
  .homethinkbtn {
    padding: 1rem;
    width: 180px;
    font-size: 14px;
  }
}

/*Hear From Clients Responsiveness*/

@media (max-width: 1200px) {
  .clientimg {
    width: 70px;
    height: 70px;
  }
  .clienttext {
    width: 250px;
    font-size: 12px;
  }
}

@media (max-width: 1000px) {
  .homemapimg {
    right: -44px;
  }
  .mapdesktop {
    display: none;
  }
  .mapmobile {
    display: block;
    margin: auto;
    margin-top: 3rem;
  }
  .homemaptitle {
    width: 1000%;
    text-align: left;
  }
  .homemapsubsec {
    flex-direction: column;
    height: 350px;
  }
  .clienttext {
    width: 350px;
    margin: 0;
    margin-top: 2rem;
    font-size: 14px;
    line-height: 27px;
  }
  .mapmobile .carousel-indicators {
    bottom: -3.5rem;
  }
  .mapmobile .carousel-indicators li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .mapmobile .carousel-control-prev-icon {
    margin-left: -10rem;
  }
  .mapmobile .carousel-control-next-icon {
    margin-right: -7rem;
  }
  .mapname {
    margin-top: 2rem;
    font-family: 'Brfirma-Bold';
    letter-spacing: 1px;
  }
  .clientimg {
    width: 80px;
    height: 80px;
  }
  .homemapline {
    margin-top: 4rem;
  }
  .homemapbtmtxt {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .homemapimg {
    right: -32px;
  }
  .homemapbtmtxt {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .homemapimg {
    right: -21px;
  }
  .mapmobile .carousel-control-prev-icon {
    margin-left: -8rem;
  }
  .mapmobile .carousel-control-next-icon {
    margin-right: -6rem;
  }
  .clienttext {
    width: 320px;
  }
  .homemap {
    padding: 3rem 2rem;
  }
}

@media (max-width: 450px) {
  .mapmobile .carousel-control-prev-icon {
    display: none;
  }
  .mapmobile .carousel-control-next-icon {
    display: none;
  }
  .homemapbtmtxt {
    text-align: center;
    padding: 0 1rem;
  }
}

@media (max-width: 400px) {
  .homemaptitle {
    margin-left: -1rem;
  }
  .homemapimg {
    right: -10px;
    width: 95px;
  }
  .clientimg {
    width: 70px;
    height: 70px;
  }
  .clienttext {
    font-size: 13px;
    width: 90%;
    text-align: justify;
  }
  .homemapsubsec {
    padding: 0 0.5rem;
    height: 420px;
  }
}

/*About Us Responsiveness*/
@media (max-width: 1200px) {
  .withclaritytxt {
    max-width: 60%;
  }
  .withclarityimg {
    max-width: 40%;
  }
  .profimg1 {
    height: 400px;
    margin-top: -4rem;
  }
  .profimg2 {
    height: 450px;
    margin-top: 5rem;
  }
  .profimg1b {
    width: 60%;
  }
  .profimg2a,
  .profimg1a {
    width: 100%;
  }
  .profimg2b {
    width: 80%;
  }
  .greenimg1 {
    width: 100%;
  }
  .sec4img {
    margin-top: 10%;
  }
}

@media (max-width: 1000px) {
  .withclarityimg {
    display: none;
  }
  .withclaritytxt {
    max-width: 80%;
  }
  .aboutfirsthead {
    font-size: 36px;
    margin-top: 10rem;
  }
  .aboutfirsttxt {
    font-size: 14px;
    margin-bottom: 3rem;
    margin-right: 25%;
  }
  .prodsec2 {
    padding-left: 1rem;
    font-size: 13px;
  }
  .prodgreentick {
    height: 20px;
    margin: 0 1.5rem;
  }
  .for1 {
    margin-left: 1rem;
  }
  .aboutfirstsec {
    padding-left: 3.5rem;
  }
}

@media (max-width: 800px) {
  .aboutfirsttxt {
    font-size: 13px;
    margin-bottom: 2rem;
    margin-right: 10%;
  }
  .prodsec1 {
    order: 1;
    max-width: 70%;
    flex: 80%;
  }
  .prodsec2 {
    order: 2;
    flex: 80%;
    max-width: 100%;
  }
  .prodsec3 {
    order: 4;
    flex: 80%;
    max-width: 100%;
  }
  .prodsec4 {
    order: 3;
    flex: 80%;
    max-width: 70%;
  }
  .sec4img {
    padding: 0;
    margin: 0;
  }
  .greenimg1 {
    width: 60%;
  }
  .abtprod {
    font-size: 18px;
  }
  .abtprodsec {
    margin-top: 2rem;
  }
}

@media (max-width: 600px) {
  .abtwithclarity {
    font-size: 24px;
    margin: 5rem 0 1rem 3rem;
  }
  .withclaritytxt {
    padding-left: 0rem;
    font-size: 15px;
    margin-left: 2rem;
    max-width: 90%;
    padding-top: 1rem;
  }
  .aboutfirsttxt {
    margin-bottom: 2rem;
    margin-right: 5%;
  }
  .aboutfirsthead {
    font-size: 32px;
    margin-top: 6rem;
  }
  .aboutfirstsec {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .abtticktxt {
    padding-left: 1rem;
  }
  .tickdash1 {
    width: 30px;
  }
  .abthapprod {
    padding: 3rem;
  }
}

@media (max-width: 400px) {
  .aboutfirstsec {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .aboutfirsthead {
    margin-top: 4rem;
    font-size: 30px;
    padding-right: 2rem;
  }
  .abtwithclarity {
    font-size: 20px;
    margin: 4rem 0 1rem 2rem;
  }
  .withclaritytxt {
    font-size: 14px;
  }
  .abthapprod {
    padding: 2rem;
  }
}

/*Navbar Responsiveness*/
@media (max-width: 1000px) {
  .redoNav {
    padding-left: 2.5rem;
    padding-right: 2.65rem;
  }
  .nav_titlenew {
    font-size: 13px;
  }
  div > .title1{
    padding-left: 0;
  }
  .newlogin {
    font-size: 12px;
    width: 80px;
    height: 30px;
    padding: 0.3rem 1rem;
  }
  div > .newshft {
    padding-left: 0;
  }
  .logologo {
    width: 40px;
  }
}

.newflexsss {
  background: #23304c;
  margin-right: -2px;
  position: fixed;
  width: 100vw;
  z-index: 99;
  transition: 0.5s;
  box-shadow: 0 4px 2px -2px rgba(35, 48, 76, 30%);
}
.lakk .mobilelogo {
  margin-left: 1rem;
  width: 40px !important;
  height: 45px !important;
}
.newline {
  background: #ffffff;
}
.shrtline {
  width: 15px;
  margin-left: 15px;
}
.space {
  margin-right: 1rem;
}

@media (max-width: 800px) {
  .newhamburger {
    background: transparent !important;
  }
  .nav_titlenew {
    font-size: 12px;
  }
}

/*Faq*/
.newfaq {
  font-family: 'Brfirma-Bold';
  color: #23304c;
}
.newfaqrow {
  padding-top: 10rem;
}
.newfaqwrapper {
  padding-bottom: 3rem;
}

@media(max-width: 767px) {
  .newfaqrow {
    padding-top: 4rem;
  }
  .addpadding {
    padding-top: 0rem;
    padding-bottom: 2rem;
  }
}

.unclick {
  cursor: not-allowed;
}
.addpadding {
  padding-top: 8rem;
  padding-bottom: 3rem;
}
@media(max-width: 767px) {
  .addpadding {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.sidebarlogo {
  width: 45px;
    margin-top: 1rem !important;
}
.side_navv {
  padding-top: 3rem !important;
}
.newlinedash {
  width: 14px !important;
  margin-left: 14px;
}
.hamdash {
  background: transparent !important;
}
.whitenav {
  background: #ffffff;
}
.darkline {
  background: #9c1258;
}
.inapplogout {
  background: #9c1258;
  color: #ffffff;
  font-weight: 800;
}
.inapplogout:hover {
  background: #ffffff;
  color: #9c1258;
}

/*Counselor Dashboard Changes*/
.newitem {
  font-size: 11px !important;
}

/*Hover Effects*/
.fourthsubsechov {
  transition: 0.2s;
  position: relative;
  z-index: 10;
}
.fourthsubsechov:hover {
  transform: scale(1.1);
}
@media(max-width: 800px) {
  .fourthsubsechov:hover {
    transform: scale(1.09);
  }
}

/*Fixed External Mobile Nav*/
@media(max-width: 779px) {
  .mobilepadding {
    padding-top: 73px;
  }
  .mobilepaddingredo {
    padding-top: 50px;
  }
  .fourthsubsechov {
    z-index: 1;
  }
}

.lightnavlist {
  color: #9c1258 !important;
}

@media(max-width: 1200px) {
  .trustimg, .fourthsubhead, .fourthsubtext {
    z-index: 1;
  }
}

/*Fixed Dashboard Mobile Nav*/
.fixeddashnav {
  position: fixed;
    width: 100vw;
    z-index: 100;
    background: #ffffff;
}
.newsiddle {
  padding-top: 4rem !important;
}
.scrollscroll {
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 6rem;
}

.logotext-dark {
  font-family: 'Brfirma-Bold';
  color: #ffffff;
  font-size: 13px;
  margin: auto 0.5rem;
}
.logotext-light {
  font-family: 'Brfirma-Bold';
  color: #9c1258;
  font-size: 13px;
  margin: auto 0.5rem;
}
.smlogo-comm {
  display: flex;
}