.cftcontent {
  justify-content: center;
}
.xcv1 {
  margin: auto;
  width: 100%;
  max-width: 491px;
}
.firstrowcf {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.Clarity-for-Teams {
  font-size: 2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #9C125F;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.seccfr {
  font-size: 16px;
  font-weight: 300;
  /* font-family: 'Sailec-Light'; */
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #150538;
}
.lko11 {
  /* display: flex; */
  justify-content: space-between;
}
.oneofour {
  width: 100%;
  max-width: 430px;
  border-radius: 12px;
}
.lko11wr {
  margin-top: 2rem;
}
.understand {
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  padding-bottom: 1rem;
  padding-top: 1.2rem;
  letter-spacing: normal;
  text-align: left;
  color: #9C125F;
  min-height: 85px;
}
.Byhelping {
  font-size: 16px;
  font-weight: 300;
  /* font-family: 'Sailec-Light'; */
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  /* text-align: justify; */
  min-height: 224px;
  color: #150538;
}
@media (max-width: 500px) {
  .oneofour {
    padding-top: 1rem;
  }
  .understand {
    min-height: 59px;
  }
}
.Choose-the-plan {
  font-size: 1.3em;
  font-weight: 300;
  /* font-family: 'Sailec-Light'; */
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #2c2147;
}
.bgwcf {
  background-color: #f9f9f9;
}
.pricewraper {
  text-align: center;
  margin-right: 1rem;
  margin-top: 2rem;
  border-radius: 13.9px;
  padding: 1.9rem 3rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  max-width: 325px;
  padding-bottom: 4rem;
}
.pricewraper:hover {
  box-shadow: rgba(0, 0, 0, 0.195) 0px 5px 30px;
  transition: 0.5s ease-in-out;
}
.plan_ {
  font-size: 1.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: #9C125F;
}
.plancost {
  font-size: 1.8em;
  font-weight: bold;
  color: #9C125F;
}
.perteam {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.12;
  letter-spacing: normal;
  text-align: center;
  color: #d5d5d5;
}
.dd11 {
  font-size: 1em;
  font-weight: 300;
  padding-top: 0.9rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #2c2147;
}
.getstarted {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.095) 0px 5px 30px;
  background: none;
  padding: 0.5rem 2.3rem;
  top: 3rem;
  border-radius: 8.1px;
  border: solid 1px #9C125F;
  transition: 1s ease-in-out;
}
.getstarted1 {
  top: 1rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.095) 0px 5px 30px;
  background: none;
  padding: 0.5rem 2.3rem;
  border-radius: 8.1px;
  border: solid 1px #9C125F;
  transition: 1s ease-in-out;
}
.getstarted:hover {
  background: #9C125F;
  color: white;
  transition: 0.5s ease-in-out;
}
.getstarted1:hover {
  background: #9C125F;
  color: white;
  transition: 0.5s ease-in-out;
}
.navy {
  color: #001833;
}
.offe {
  padding-top: 0.9rem;
  padding-bottom: 3rem;
}
.offe11 {
  margin-top: 0px !important;
  padding-top: 1rem !important;
}
.period1 {
  font-weight: 100;
  font-size: 15px;
  padding-left: 1rem;
}
.amount12a {
  font-size: 1.5em;
  padding-top: 2rem;
}

.ing1 {
  text-align: center;
  width: 70%;
}

.wing1 {
  text-align: left;
  width: 70%;
  padding-bottom: .4rem;
  border-bottom: 1px solid #9C125F;
  padding-left: 0px;
}
.klsos{
  margin-top: 1rem !important;
}
.personalityss{
  color: #001833;
  font-size: 15px;
  font-weight: bolder;
}
.nobreak {
  word-break: break-all;
}