.fixed-sidebar {
    position: fixed;
    width: 16.777777%;
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 3rem;
    background: #001833;
}
.fixed-mobilenav {
    position: fixed;
    width: 100%;
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 3rem;
}
.cvmoveleft {
    margin-left: 1rem;
}
.cvprev {
    width: 100px !important;
    text-align: center;
    margin-top: 2rem;
}
.socialpad {
    padding-top: 1rem !important;
}
.jbdescr {
    padding-top: 1rem;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
}
.jbdescr:active, .jbdescr:hover, .jbdescr:visited {
    outline: 1px solid #e5e5e5;
}
.exiticon {
    text-align: center;
    width: 74px;
    margin-left: calc(50% - 37px);
}
.exittxt {
    text-align: center;
    margin: 2rem 0;
    font-size: 16px;
    color: #000000;
}
.exittxtsub {
    font-weight: 600;
}
.exitbtns {
    display: flex;
    justify-content: space-around;
    margin: auto;
    margin-bottom: 0.5rem;
}
.exitbtn1 {
    padding: 0.5rem;
    width: 140px;
    border-radius: 6px;
    border: 1px solid #9C125F;
    background: #ffffff;
    color: #000000;
    font-weight: 600;
    margin-right: 2rem;
}
.exitbtn2 {
    padding: 0.5rem;
    width: 140px;
    border-radius: 6px;
    border: 1px solid #9C125F;
    background: #9C125F;
    color: #ffffff;
    font-weight: 600;
}
.ffnewsize {
    font-size: 11px !important;
}
.compulsory_info {
    color: red;
}
.newrtt {
    margin-top: 1rem;
}
/*All Appointments Page*/
.apptbg {
    background: #f9f9f901;
   }
.apptmanage {
    color: #666666;
}
.apptsec1 {
    margin: 1rem 0;
}
.apptbtn {
    border: 1px solid #9C125F;
    background: #9C125F;
    color: #ffffff;
    border-radius: 5px;
    font-size: 15px;
    padding: 0.3rem 1rem;
    width: 250px;
    height: 40px;
    text-align: center;
    transition: 0.3s;
}
.appttxt1 {
    color: #666666;
    font-size: 12px;
    margin-top: 10px;
}
.apptswitch {
    display: flex;
}
.apptconf {
    background: #ffffff;
    width: 230px;
    font-size: 13px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 6px;
    color: #666666;
    cursor: pointer;
}
.apptunconf {
    background: #f9f9f9;
    width: 230px;
    font-size: 13px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    color: #666666;
    cursor: pointer;
}
.apptlist {
    box-shadow: 0px 4px 10px rgba(102, 102, 102, 0.1);
    border-radius: 0px 10px 10px 10px;
    margin-right: 2rem;
}
.apptlistttl {
    color: #666666;
    font-size: 13px;
    padding: 1rem 1.5rem;
}
.apptlisthead {
    display: flex;
    color: #666666;
    font-size: 12px;
    background: #f9f9f9;
    padding: 1rem;
    padding-left: 3rem;
}
.apptname {
    flex: 35%;
    text-transform: capitalize;
}
.apptdate {
    flex: 65%;
}
.apptlist1 {
    display: flex;
    color: #333333;
    font-size: 12px;
    padding: 1rem;
    padding-left: 3rem;
}
.appttime::before {
    content: "";
    border: 0.5px solid #666666;
    margin: 0 7px;
    display: inline-block;
    height: 21px;
    margin-bottom: -5px;
}
.apptname1 {
    flex: 35%;
    text-transform: capitalize;
}
.apptdate2 {
    flex: 55%;
}
.apptapprove {
    flex: 10%;
    padding-right: 2rem;
}
.approvebtn {
    background: #1BB978;
    color: #ffffff;
    border: 1px solid #1BB978;
    border-radius: 5px;
    width: 100px;
    height: 30px;
}
.apptselected {
    color: #1BB978;
    font-weight: 700;
}
.apptlistmarg {
    margin-top: 3rem;
}
.apptpage {
    margin-top: 2rem;
}


.schhead {
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #DADADA !important;
    width: 90%;
    margin: auto;
}
.schttl {
    font-family: 'Brfirma-Bold';
    margin-bottom: 10px;
}
.schtxt1 {
    color: #666666;
    font-size: 12px;
}
.scheduler .modal-content {
    background: #F0F5FF;
}
.scheduler .modal-dialog {
    max-width: 900px;
}
.schpref {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    justify-content: space-around;
}
.dayslct {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 240px;
}
.dayslct1 {
    background: #ffffff;
    padding: 1rem 1rem 0.8rem 2rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #DADADA;
    display: flex;
    align-items: center;
}
.dayslct2 {
    background: #ffffff;
    padding: 0.8rem 1rem 0.8rem 2rem;
    border-bottom: 1px solid #DADADA;
    display: flex;
    align-items: center;
}
.dayslct3 {
    background: #ffffff;
    padding: 0.8rem 1rem 1rem 2rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;
}
.daytxt {
    margin-left: 1.3rem;
    color: #A5A5A5;
    font-size: 13px;
}
.slctttl {
    color: #666666;
    font-size: 13px;
    text-align: center;
}
.senddate {
    color: #ffffff;
    background: #9C125F;
    border: 1px solid #9C125F;
    border-radius: 6px;
    width: 180px;
    text-align: center;
    height: 40px;
    padding: 0.5rem;
    font-size: 13px;
    margin: 2rem auto;
}
.sendbtnsec {
    text-align: center;
}
.closesched {
    position: absolute;
    right: 2.5rem;
    top: 1.5rem;
    cursor: pointer;
}
.approve .modal-dialog {
    max-width: 600px;
}
.approve .modal-content {
    background: #F0F5FF;
}
.approve .modal-body {
    padding: 1rem 4rem;
}
.approve .modal-title {
    font-family: 'Brfirma-Bold';
    font-size: 16px !important;
    text-align: center;
    margin: auto;
    color: #1BB978;
}
.apprvsec {
    background: #ffffff;
    border: 1px dashed #1BB978;
    max-width: 600px;
    padding: 1rem 2rem 2rem;
    border-radius: 6px;
    margin-bottom: 5px;
}
.apprvinput {
    width: 100%;
    background: #f9f9f9;
    outline: none;
    height: 40px;
    border-radius: 5px;
    padding-left: 1rem;
    margin-bottom: 1rem;
}
.apprvtxt {
    color: #979797;
}
.apprvsubtxt {
    font-weight: 600;
    color: #150538;
}
.apprvtxt2 {
    color: #999999;
    font-size: 12px;
    font-style: italic;
}
.apprvbtn {
    background: #1BB978;
    color: #ffffff;
    border: 1px solid #1BB978;
    border-radius: 5px;
    font-size: 13px;
    width: 170px;
    height: 40px;
    margin: 1rem 0;
}
.apprvvvbtnn {
    text-align: center;
}
.selection, .selection:hover, .selection:active {
    outline: none;
}
@media(max-width: 800px) {
    .newprm {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0 1rem !important;
    }
    .apptlist {
        margin-right: 0;
    }
    .apptname, .apptdate {
        flex: 50%;
    }
}
@media(max-width: 600px) {
    .newprm {
        padding: 0 1rem !important;
    }
    .apptconf, .apptunconf {
        width: 200px;
        line-break: normal;
        padding: 10px;
        text-align: center;
    }
    .apptlisthead, .apptlist1 {
        padding-left: 1rem;
    }
    .schpref {
        flex-direction: column;
        align-items: center;
    }
    .timeslct {
        margin-top: 3rem;
    }
    .approve .modal-body {
        padding: 1rem;
    }
    .closesched {
        right: 1.5rem;
        top: 1rem;
    }
}
@media(max-width: 400px) {
    .apprvsec {
        padding: 1rem;
    }
    .apprvtxt, .apprvinput, .apprvinput::placeholder {
        font-size: 13px;
    }
    .closesched {
        width: 25px;
    }
}

/*Individual Overview*/
.retakebtnn {
    font-size: 14px;
    height: 40px;
    transition: 0.2s;
}
.retakebtnn:hover {
    color: #ffffff !important;
    background: #9C125F !important;
}
.apptdetails {
    background: #001833;
    color: #ffffff;
    display: flex;
    padding: 2rem;
    width: 96%;
}
.deetsec1 {
    flex: 15%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.deetsec1::after {
    content: "";
    border: 1px solid #A7ADFF;
    height: 100px;
}
.calicon {
    width: 80px;
    height: 80px;
    margin-right: 1rem;
}
.deetsec2 {
    flex: 70%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.deetttl {
    font-family: 'Brfirma-Bold';
    letter-spacing: 1px;
    margin-bottom: 1rem;
    font-size: 16px;
}
.deettxt{
    font-size: 14px;
    letter-spacing: 0.5px;
}
.deetsec3 {
    flex: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reschbtn {
    border: 1px solid #9C125F;
    border-radius: 6px;
    background: #9C125F;
    color: #ffffff;
    height: 40px;
    width: 150px;
    font-size: 13px;
    margin: auto;
    font-family: 'Brfirma-Bold';
    letter-spacing: 0.3px;
    min-width: 120px;
}
.listsecc {
    margin-top: 2rem;
    box-shadow: 0px 8px 12px rgba(0, 24, 51, 0.16);
    border-radius: 6px;
    padding: 2rem;
    width: 96%;
}
.listheadd {
    padding: 0 1.5rem;
}
.listttll {
    font-family: 'Brfirma-Bold';
}
.listtxtt {
    color: #666666;
    font-size: 12px;
    margin-top: 5px;
}
.listdvd {
    width: 96%;
    margin-left: 0;
}
.listlist {
    padding: 2rem 1.5rem;
    font-size: 14px;
    color: #A4A6B3;
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: space-between;
    position: relative;
}
.progressline {
    position: absolute;
    height: 270px;
    margin-left: 10px;
    margin-top: 8px;
}
.overviewlist {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.listtick {
    width: 20px;
    margin-right: 1.5rem;
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.apptdeetbold {
    font-family: 'Brfirma-Bold';
}
@media(max-width: 800px) {
    .newkisls {
        padding-right: 0 !important;
    }
    .calicon {
        width: 60px;
        height: 60px;
    }
    .apptdetails {
        padding: 1rem;
    }
}
@media(max-width: 600px) {
    .deetsec3 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .deetsec3 {
        margin: 1rem 0;
    }
    .apptdetails {
        flex-wrap: wrap;
    }
}

/*Individual Dashboard Appointment Scheduler*/ 
.indscheduler {
    background: #F0F5FF;
    padding: 2rem 0;
}
.indttl {
    font-size: 20px;
    font-family: 'Brfirma-Bold';
    margin-bottom: 10px;
}
.indtxt {
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #666666;
}
.indschdates {
    max-width: 880px;
    margin: 3rem auto 1rem auto;
}
.indcalttl {
    text-align: left;
    padding-left: 1rem;
}
.calslct {
    margin-top: 1rem;
}
.calslct .react-calendar__month-view__days {
    height: 280px;
}
.calslct .react-calendar {
    max-width: 600px;
    margin-right: 2rem;
    border-radius: 8px;
}
@media(max-width: 1000px) {
    .calslct .react-calendar {
        max-width: 500px;
        margin-right: 2rem;
    }
    
    .indschdates {
        max-width: 780px;
    }
}
@media(max-width: 800px) {
    .calslct .react-calendar {
        max-width: 400px;
        margin-right: 1rem;
    }
    .indschdates {
        max-width: 680px;
    }
}
@media(max-width: 700px) {
    .calslct .react-calendar {
        margin-bottom: 3rem;
        margin-right: 0;
    }
    .indschdates {
        flex-direction: column;
        align-items: center;
    }
    .indcalttl {
        padding-left: 0;
        text-align: center;
    }
}

.apptpreloader1 {
    width: 100vw;
    height: 100vh;
    top: 4rem;
    left: 0;
    bottom: 0;
    background: #f9f9f980;
    position: absolute;
    z-index: 2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .apptpreloader {
    width: 250px;
  }

/*Success Modal*/
.success-mod .modal-body {
    text-align: center;
}
.success-modtxt1 {
    font-size: 22px;
    font-family: 'Brfirma-Bold';
    margin-bottom: 1rem;
}
.success-modbtn {
    margin-top: 2rem;
    background: #9C125F;
    border: 1px solid #9C125F;
    color: #ffffff;
    border-radius: 6px;
    width: 100px;
    height: 40px;
    transition: 0.2s;
}
.success-modbtn:hover, .apptbackbtn:hover {
    background: #ffffff;
    color: #9C125F;
}
.apptbackbtn {
    color: #ffffff;
    border: 1px solid #9C125F;
    background: #9C125F;
    position: absolute;
    border-radius: 6px;
    height: 40px;
    right: 2rem;
    top: 1.5rem;
    font-size: 14px;
    width: 70px;
}
.progress-check {
    color: #46d68c;
}
.calslct .react-calendar button:hover, .calslct .react-calendar button:active, .calslct .react-calendar button:visited, .calslct .react-calendar button:focus {
    background: #9C125F !important;
    color: #ffffff !important;
}
.calslct .selectedDate {
    background: #9C125F !important;
    color: #ffffff !important;
}

.deetsec3 .disableresch {
    background: grey;
    border: 1px solid grey;
    cursor: not-allowed;
}
.nono {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    letter-spacing: normal;
}
.nonosubtxt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    letter-spacing: normal;
    text-align: center;
    line-height: 20px;
}
.nonobtn {
    width: 90%;
    border-radius: 5px;
    height: 40px;
    background: #9C125F;
    border: 1px solid #9C125F;
    font-size: 14px;
    color: #ffffff;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    transition: 0.3s;
}
.nonobtn:hover {
    transform: scale(1.05);
}

@media(max-width: 600px) {
    .apptbackbtn {
        right: 1rem;
        top: 1rem;
        height: 35px;
        width: 60px;
        font-size: 13px;
    }
}
@media(max-width: 450px) {
    .apptbackbtn {
        right: 0.5rem;
        top: 0.5rem;
        height: 30px;
        width: 55px;
        font-size: 12px;
        border-radius: 4px;
    }
}
.infoforsave {
    font-size: 12px;
    letter-spacing: normal;
    background: #3964ff23;
    color: #3965ff;
    padding: 0.5rem;
    font-weight: 400;
    margin-left: 0.5rem;
}
.sskill {
    padding-top: 3rem;
}
.dateinputt {
    padding-top: 1rem;
}
.centerplans {
    margin: 2rem auto;
    padding: 2rem;
    border-top: 5px solid #ffffff;
}
.centerplans:hover {
    border-top: 5px solid #9C125F;
    border-radius: 8px;
}

/*Admin Overview Details*/
.totallistbodyttl {
    width: 97%;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    color: #666666;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
}
.totallistbody {
    box-shadow: 0px 2px 6px rgba(24, 33, 77, 0.25);
    width: 97%;
    border-radius: 6px;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    font-family: 'Brfirma-Bold';
    margin-bottom: 1rem;
}
.totalname {
    flex: 30%;
}
.totalsubname {
    color: #666666;
    font-size: 13px;
    font-family: 'Brfirma-Regular';
    margin-top: 5px;
}
.totalarea {
    flex: 55%;
}
.totaldate {
    flex: 15%;
}
.mobottl {
    display: none;
}
@media (max-width: 800px) {
    .totalname {
        flex: 35%;
        padding-right: 1rem;
        min-width: 160px;
    }
    .totalarea {
        flex: 45%;
    }
    .totaldate {
        flex: 20%;
        padding-left: 1rem;
    }
}
@media (max-width: 500px) {
    .totallistbody {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }
    .totalarea {
        margin-top: 1rem;
    }
    .totaldate {
        margin-top: 1rem;
        padding-left: 0;
    }
    .totallistbodyttl {
        display: none;
    }
    .mobottl {
        display: block;
        color: #666666;
        font-size: 12px;
    }
}
.takeassess {
    min-width: 220px;
    width: 70%;
}
.fileContainer .deleteImage {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #ff4081;
    background: white;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fileContainer .uploadPictureContainer {
    width: 14% !important;
    margin: 2% !important;
    padding: 7px;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
    border: 1px solid #d0dbe4;
    position: relative;
}

.addinterviewer {
    background: #9C125F;
    border: 1px solid #9C125F;
    height: 40px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    width: 180px;
    letter-spacing: 0.3px;
    transition: 0.3s;
}
.addinterviewer:hover, .apptbtn:hover {
    transform: scale(1.1);
}

.interviewers {
    width: 97%;
    margin: 2rem 0;
}
.interviewerinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e5e5e5;
    padding: 0 2rem;
    border-radius: 5px;
}
.interviewermail {
    height: 40px;
    min-width: 250px;
    width: 600px;
    padding: 0 1rem;
    margin: 2rem 0;
    border-radius: 5px;
    outline: none;
    margin-right: 3rem;
}
.interviewerbtn {
    color: #ffffff;
    border: 1px solid #9C125F;
    background: #9C125F;
    border-radius: 5px;
    height: 40px;
    width: 120px;
    font-size: 15px;
}
.interviewerlistsec {
    margin: 3rem 0;
}
.interviewerlistsec > h6 {
    font-family: 'Brfirma-Bold';
}
.interviewerlist {
    margin-top: 1.5rem;
}
.interviewercard {
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 6px rgb(24 33 77 / 25%);
    padding: 1rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    justify-content: space-between;
}
.interviewercard > p {
    margin: 0;
    font-size: 14px;
    color: #333333;
    font-family: 'Brfirma-Bold';
}
.removeinterviewer {
    color: red !important;
    cursor: pointer;
    margin-left: 2rem;
}
.nointerviewersec {
    text-align: center;
}
.nointerviewer {
    width: 60%;
    margin-bottom: 2rem;
}
.nointerviewersec > h5 {
    font-family: 'Brfirma-Bold';
}
@media(max-width: 800px) {
    .interviewers {
        width: 100%;
    }
}
@media(max-width: 600px) {
    .interviewerinput {
        padding: 0 1rem;
    }
    .interviewermail {
        margin: 1.5rem 0;
        margin-right: 1rem;
    }
    .interviewers {
        margin: 0;
    }
    .addinterviewer {
        width: 150px;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
@media(max-width: 400px) {
    .interviewerinput {
        flex-direction: column;
        padding-bottom: 1rem;
    }
    .interviewermail {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
        min-width: none;
    }
    .interviewerbtn {
        width: 100%;
    }
    .addinterviewer {
        right: 5px;
    }
    .newfjss {
        margin-top: -1rem;
    }
}
@media(max-width: 350px) {
    .newfjss {
        margin-top: 1rem !important;
    }
}

.externallogo {
    width: 40px;
    position: relative;
    top: 1rem;
    left: 2rem;
}
.emailinput {
    height: 40px;
    width: 100%;
    padding-left: 2rem;
    margin-bottom: 1rem;
}
.backtoportal {
    cursor: pointer;
    width: 100px;
    height: 40px;
    background: #9C125F;
    color: #ffffff !important;
    border-radius: 5px;
    border: 1px solid #9C125F;
    display: block;
    margin-bottom: 1rem;
    margin-top: -1rem;
    float: right;
}
.backtoportal1 {
    margin: 1rem;
    float: none;
}
.resultcomment {
    width: 100%;
    height: 80px;
    max-height: 80px;
    margin: 0.5rem 0 2rem 0;
    padding: 5px 10px;
}
.commentbtn {
    background: #9C125F;
    border: 1px solid #9C125F;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    margin-bottom: 3rem;
}
.noresult {
    margin: 1rem;
    font-size: 20px;
    color: #9C125F;
    text-align: center;
}