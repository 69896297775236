.pricing-container {
    padding: 10rem 0 7rem;
    background: #23304c;
}
.pricing-decription{
    padding-bottom: 3rem;
    color: #fff;
    text-align: center;
}
.pricing-decription>h3{
    font-size: 20px;
    font-family: 'brfirma-bold';
}
.pymntline {
    margin-top: 1.2rem;
}
.pricing-list {
    background: #ffffff;
    border-radius: 2px;
    padding: 2rem 2.5rem;
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}
.pricing-list-heading {
    display: flex;
    padding: 2rem 1rem;
    color: #333333;
    font-size: 14px;
    font-family: 'Brfirma-Bold';
    border-bottom: 1px solid #bcbcbc;
}
.pricing-list-ttl {
    flex: 30%;
    font-size: 13px;
    padding-right: 1rem;
}
.pricing-list-txt {
    flex: 17%;
    font-size: 15px;
    letter-spacing: 0.3px;
    padding-right: 1rem;
}
.pricing-list-prices {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #bcbcbc;
}
.pricing-list-prices-amt {
    color: #001833;
    font-size: 20px;
    font-family: "Brfirma-Bold";
}
.pricing-list-prices-amt > span {
    font-family: "Brfirma-Regular";
    font-size: 12px;
}
.pricing-list-prices-descrip {
    margin-top: 10px;
    font-size: 12px;
}
.pricing-txt-color {
    color: #666666;
}
.pricing-ttl-color {
    color: #333333;
    font-family: "Brfirma-Bold";
}
.pricing-list-features {
    background: #f6f6f6;
    border-bottom: 1px solid #bcbcbc;
    padding: 1rem;
    font-size: 13px;
}
.pricing-list-features-list {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #bcbcbc;
    align-items: center;
}
.pricing-buttons {
    display: flex;
    padding: 1rem;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 10rem;
}
.pricing-nb {
    font-size: 12px;
}
.pricing-buttons button {
    border: 1px solid #9c1258;
    background: #9c1258;
    color: #ffffff;
    border-radius: 6px;
    height: 40px;
    font-size: 13px;
    padding: 5px 10px;
    transition: 0.3s;
    width: 130px;
}
.pricing-buttons button:hover {
    transform: scale(1.1);
}
.mobile-pricing-list {
    display: none;
}
@media(max-width: 1000px) {
    .pricing-list {
        padding: 1rem 1.5rem;
    }
    .pricing-buttons button > {
        width: 120px;
    }
}
@media screen and (max-width: 800px) {
    .pricing-list {
        display: none;
    }
    .mobile-pricing-list {
        display: block;
        width: 280px;
        margin: auto;
        outline: none;
        margin-top: 2rem;
    }
    .mobile-pricing-list-card {
        background: #ffffff;
        border: 1px solid #BCBCBC;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 3.5rem 1.5rem 2.5rem;
        text-align: left !important;
    }
    .mobile-pricing-list-card-dash {
        box-shadow: 0px 2px 6px rgb(24 33 77 / 25%);
        border: none;
    }
    .mobile-pricing-list-card-ttl {
        color: #333333;
        font-size: 17px;
        font-family: 'Brfirma-Bold';
        margin-bottom: 1rem;
    }
    .mobile-pricing-list-card-txt {
        color: #666666;
        font-size: 14px;
        margin-bottom: 2rem;
    }
    .mobile-pricing-list-card-btn {
        width: 100%;
        height: 40px;
        color: #ffffff;
        background: #9c1258;
        border: 1px solid #9c1258;
        border-radius: 5px;
        font-size: 15px;
    }
    .mobile-pricing-list-expand {
        background: #ffffff;
        padding: 1rem 0rem;
        border-bottom: 1px solid #bcbcbc;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
    .mobile-pricing-list-expand-dash {
        box-shadow: 0px 2px 6px rgb(24 33 77 / 25%);
        border: none;
    }
    .mobile-pricing-list-expand-header {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        cursor: pointer;
        text-align: left;
    }
    .mobile-pricing-list-expand-header > p {
        color: #333333;
        font-family: 'Brfirma-Bold';
        margin-right: 1.5rem;
        font-size: 16px;
    }
    .mobile-pricing-list-expand-header > img {
        width: 18px;
    }
    .mobile-pricing-list-expand-content {
        padding: 0rem 1.5rem;
    }
    .mobile-pricing-list-expand-content-list {
        display: flex;
        align-items: baseline;
        text-align: left !important;
    }
    .mobile-pricing-list-expand-content-list > img {
        margin-right: 1rem;
    }
    .mobile-pricing-list-expand-content-list > p {
        font-size: 14px;
        color: #333333;
    }
    .under-basic {
        color: darkgoldenrod;
        font-family: "Brfirma-Bold";
    }
    .under-standard {
        color: darkviolet;
        font-family: "Brfirma-Bold";
    }
    .under-premium {
        color: darkblue;
        font-family: "Brfirma-Bold";
    }
    .pricing-carousel {
        margin-top: 3rem;
    }
    .pricing-carousel-dash {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    .react-multi-carousel-item .mobile-pricing-list {
        transform: scale(0.8);
        width: 100%;
        opacity: 0.5;
    }
    .react-multi-carousel-item--active {
        display: flex;
        justify-content: center;
    }
    .react-multi-carousel-item--active .mobile-pricing-list {
        transform: scale(1.0);
        width: 280px;
        opacity: 1;
        margin: auto;
    }
}
@media(max-width: 600px) {
    .pricing-decription {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .react-multi-carousel-item .mobile-pricing-list {
        width: 100%;
    }
    .react-multi-carousel-item--active .mobile-pricing-list {
        width: 250px;
    }
    .pricing-carousel {
        margin-top: 1rem;
    }
}
.pricing-input {
    display: block;
    margin: 2rem 0;
    width: 100%;
    height: 40px;
    padding-left: 1rem;
}
.pricing-modal-ttl {
    font-family: 'Brfirma-Bold';
    margin-bottom: 2rem;
    text-align: center;
}
.pricing-modal-btn {
    width: 100%;
    height: 40px;
    color: #ffffff;
    background: #9c1258;
    border: 1px solid #9c1258;
    border-radius: 5px;
    font-size: 15px;
    transition: 0.3s;
}
.pricing-modal-btn:hover {
    color: #9c1258;
    background: #ffffff;
}
.btn-spinner {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #9c1258;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    margin: 1rem auto;
}
.center-it {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}


/* In Dashboard Styling */
.pricing-container-dash {
    background: #ffffff;
    padding-top: 1rem;
}
.pricing-list-dash {
    box-shadow: 0px 2px 6px rgb(24 33 77 / 25%);
    width: 96%;
}