.signupimg {
  width: 100%;
  max-width: 425px;
}
.mo {
  display: flex;
  justify-content: flex-end;
}
.kli {
  padding-top: 3rem;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding-bottom: 4rem;
}
@media (min-width: 768px) {
  .kli {
    margin-top: 3rem;
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
@media (max-width: 500px) {
  .mo {
    justify-content: center;
  }
  .signupimg {
    width: 50%;
  }
}
.signwa {
  font-size: 1.8em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #343434;
}
.signwa1 {
  font-size: 1.4em;
  font-weight: 100;
  font-stretch: normal;
  padding-bottom: 1.3rem;
  font-style: normal;
  line-height: 1.29;
  padding-top: 0.5rem;
  letter-spacing: normal;
  color: #343434;
}
.signenter {
  font-size: 1em;
  padding-bottom: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}
.feild1 {
  border: solid 1px #707070;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}

.field1.form-control {
  color: #343434a2;
  font-size: 0.9em;
}
.field1::placeholder {
  color: #343434a2;
  font-size: 0.9em;
}
.subbtn {
  width: 100%;
  border-radius: 4px;
  background-color: #9C125F;
  border-color: #9C125F;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
}
.subbtn:focus {
  outline: none;
  border: none;
}
.subbtn:hover {
  background-color: #9C125F;
  border-color: #9C125F;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9C125F;
  border-color: #9C125F;
}
.alreadyhave {
  font-size: 0.9em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: #343434;
}
.text-divider {
  margin: 0.5em 0;
  line-height: 0;
  text-align: center;
  color: #495057;
}
.text-divider span {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.centeredline {
  border: 1px solid #eff0f1;
}
.logn {
  color: #0070e3;
  cursor: pointer;
}
.socialwrapper {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
}
.socialIcons1 {
  border: 1px solid #345495;
  border-radius: 5px;
  width: 25%;
  text-align: center;
  cursor: pointer;
}
.socialIcons2 {
  border: 1px solid #dd5145;
  border-radius: 5px;
  width: 25%;
  max-width: 26%;
  text-align: center;
  cursor: pointer;
}
.socialwrapper > img {
  cursor: pointer;
}
.socialIcons3 {
  border-radius: 5px;
  border: solid 1px #345495;
  cursor: pointer;
  width: 25%;
  text-align: center;
}
.gooogg {
  max-width: 28px;
  margin-top: 2px;
  margin-bottom: 0.4rem;
}
.cat1signup {
  border: 1px solid #001833;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #001833;
  font-weight: bold;
  flex: 1;
  padding: 1rem;
}
.cat2signup {
  border: 1px solid #001833;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #001833;
  font-weight: bold;
  flex: 1;
  padding: 1rem;
}
.OrgIsActive {
  background: #001833;
  color: #fff;
}
.cat2signup:hover,
.cat1signup:hover {
  background: #001833da;
  border: 1px solid #001833ec;
  color: #fff;
  cursor: pointer;
}
.coming > .modal-dialog {
  width: 200px !important;
  margin: auto;
}
.comingsoon{
  width: 100%;
}
/* .coming {
  width: 200px;
} */
.selectcat {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 1rem;
}
.navv {
  color: #001833;
}
.enter {
  font-weight: bold;
  font-size: 14px;
  color: #001833;
  padding: 0.5rem 0rem;
}


/* C4C SIGN UP */
.c4csignup {
  background: #23304c;
  padding-top: 10rem;
}
.c4csignup > .kli {
  border-radius: 8px;
  min-width: 300px;
  max-width: 550px;
  margin: auto;
  padding: 2rem 1rem;
  margin-bottom: 5rem;
}
.signin-heading {
  font-size: 24px;
}